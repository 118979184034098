import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { TariffMappingConstant } from "./TariffMappingConstant";

const TARIFF_MAPPING_CONSTANT = TariffMappingConstant.Table;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_TARIFF_MAPPING_COL_DEF: any[] = [

    {
        headerName: TARIFF_MAPPING_CONSTANT.PRIORITY,
        field: "priority",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        },
        {
            headerName: TARIFF_MAPPING_CONSTANT.CHARGE_TYPE,
            field: "chargeType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.CHARGEON_COMPANY,
            field: "chargeOnCompanyCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.CONSORTIUM_CODE,
            field: "consortiumCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.CONTAINER_PHYSICAL_HANDLING_CODE,
            field: "physicalHandlingCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 300,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.MODALITY_OF_ARRIVAL,
            field: "modalityOfArrival",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.MODALITY_OF_DEPARTURE,
            field: "modalityOfDeparture",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 230,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.OVER_WIDTH_IND,
            field: "overWeightInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.OVER_DIMENSION_IND,
            field: "overDimensionInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.BUNDLE_IND,
            field: "bundleInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.STATUS,
            field: "cntrStatus",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 120,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.SP_HANDLING_IND,
            field: "specialHandlingInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.SP_HANDLING_IND_D,
            field: "legSpHandlingInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.SERVICE_CODE,
            field: "serviceCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.SIZE,
            field: "cntrSize",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 100,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.TYPE,
            field: "cntrType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 100,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.DIRECT_DISCHARGE_IND,
            field: "directInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.SPECIAL_IND,
            field: "specialInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.CHARGE_IND,
            field: "chargeInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.TRANSHIPMENT_LEG_CHARGE_IND,
            field: "tsLegChargeInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 280,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.SUB_CHARGE_TYPE,
            field: "subChargeType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.LOA_LIMIT_FROM,
            field: "loaLimitFrom",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 170,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.LOA_LIMIT_TO,
            field: "loaLimitTo",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 160,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.REEFER_HANDLING_TYPE,
            field: "reeferHandlingType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 230,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.REHANDLING_CANCEL_SHIP_TYPE,
            field: "rcType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 280,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.MARSHALLING_TYPE,
            field: "marshallingType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.MARSHALLING_FROM,
            field: "marshallingFrom",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.MARSHALLING_TO,
            field: "marshallingTo",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 160,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.FROM_TML,
            field: "fromTml",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 130,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.TO_TML,
            field: "toTml",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 120,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.SERVICE_ORDER_CODE,
            field: "serviceOrderCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.OVER_WEIGHT_FROM,
            field: "overWeightFrom",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.OVER_WEIGHT_TO,
            field: "overWeightTo",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 160,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.SHIPMENT_TYPE,
            field: "shipmentType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.NONCELLULAR_VSL_IND,
            field: "nonCellularVslInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.WIRE_SLING_IND,
            field: "wireSlingInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.PALLETIZED_IND,
            field: "palletizedInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.CARGO_TYPE,
            field: "cargoType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.CARGO_CODE,
            field: "cargoCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.SHIFT_CODEOPS,
            field: "shiftCodeOps",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.CNTR_TYPE_GROUP,
            field: "cntrTypeGroup",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.SPECIAL_AREA,
            field: "specialArea",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.ROUND_TRIP,
            field: "roundTripFlag",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.OPS_LINE,
            field: "opsLine",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 120,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.FORWARDER_CODE,
            field: "forwarderCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.FORWARDER_REF,
            field: "forwarderRef",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.EQUIPMENT_CODE_IND,
            field: "equipmentCodeInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 200,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.EMPTY_INDICATOR,
            field: "emptyIndicator",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.PARTNER_CODE,
            field: "partnerCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.TRANSPORTATION_STATUS,
            field: "cntrTspStatus",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 230,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.OPERATION_TYPE,
            field: "operationType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 180,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.TARIFF_TYPE,
            field: "tariffType",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
            {
            headerName: TARIFF_MAPPING_CONSTANT.TARIFF_CODE,
            field: "tariffCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 150,
            },
        {
        headerName: TARIFF_MAPPING_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
        },
        
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}
import { SelectionChangedEvent } from "ag-grid-community";
import { TariffMappingEntity } from "domain/entity/TariffMapping/TariffMappingEntity";
import _ from "lodash";
import { INITIAL_TARIFF_MAPPING_COL_DEF } from "presentation/constant/TariffMapping/TariffMappingColumnDefinition";
import { useTariffMappingVM } from "presentation/hook/TariffMapping/useTariffMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffMappingTracked } from "presentation/store/TariffMapping/TariffMappingProvider";
import { changeCursor } from "presentation/view/components/NbisRightClickMenu/RightClickMenuAssist";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";
const TariffMappingTablePanel = () => {
    const gridRef: any = useRef(null);
    const [tariffMappingState] = useTariffMappingTracked();
    // const {tariffMappingList,updatedRows} = tariffMappingState;
    const tariffMappingVM = useTariffMappingVM();
    const messageBarVM = useMessageBarVM();

    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const rightClickRef: any = createRef();
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<TariffMappingEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        setOnTableSelectionClicked(true);
        tariffMappingVM.updateSelectedRows(selectedRows);
    }, [tariffMappingVM])

    // const handleRowDrag = useCallback((e: any, updatedRows: TariffMappingEntity[], movedIndex: number, overIndex: number)=>{
    //     tariffMappingVM.onRowDrag(updatedRows);
    // },[tariffMappingVM])


    const handleRowDoubleClick = useCallback((entity: TariffMappingEntity) => {
        // if(updatedRows.length > 0){
        //     messageBarVM.showError("Modified data has not been saved.Please save or refersh the data first."); 
        //     return;
        // }
        tariffMappingVM.onRowDoubleClick(entity);
    }, [tariffMappingVM])

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return tariffMappingState.selectedRows;
    }, [tariffMappingState.selectedRows]);

    const getShowMoveCursor = useCallback(() => {
        return showMoveCursor;
    }, [showMoveCursor]);
    //for right click menu with move after end

    useEffect(() => {
        const columnDefs = (INITIAL_TARIFF_MAPPING_COL_DEF.slice());

        if (!tariffMappingState.selectedRows || tariffMappingState.selectedRows.length <= 0) {
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
        changeCursor("tariff-mapping-table", getShowMoveCursor());
    });

    useEffect(() => {
        if (onTableSelectionClicked) return;

        gridRef?.current?.gridRef.current.api?.deselectAll();
        changeCursor("tariff-mapping-table", getShowMoveCursor());
    }, [getShowMoveCursor, onTableSelectionClicked]);

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(tariffMappingState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, tariffMappingState.selectedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        tariffMappingVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [tariffMappingVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(tariffMappingState.tariffMappingList);
        tariffMappingVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [tariffMappingState.tariffMappingList, tariffMappingVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        tariffMappingVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showWarining('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                tariffMappingVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                tariffMappingVM.searchAllTariffMappingList().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    tariffMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    tariffMappingVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [messageBarVM, tariffMappingVM]);


    const memoTariffMappingTable = useMemo(() => {

        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
                    <HPHTable
                        id='tariff-mapping-table'
                        isNewColumnSetting={true}
                        columns={INITIAL_TARIFF_MAPPING_COL_DEF}
                        data={allRows ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={false}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={false}
                        isRowHighligted={true}
                        onSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: TariffMappingEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 125px)"
                        ref={gridRef}
                        // isRowDrag={true}
                        // onRowDragged={handleRowDrag}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                        onBodyScroll={(e: any) => changeCursor("tariff-mapping-table", getShowMoveCursor())}
                    />
                </div>
            </>
        );
    }, [allRows, allowUpdate, getAllRows, getSelectedRows, getShowMoveCursor, handleRowDoubleClick, handleSelectionChange, onMoveCancel, onRefreshRow, onSaveMove, rightClickRef, showMoveCursor])

    useEffect(() => {
        if (initialAllRows && tariffMappingState.tariffMappingList && !_.isEmpty(tariffMappingState.tariffMappingList)) {
            setAllRows(tariffMappingState.tariffMappingList.map((tariffMappingEntity, index) => ({
                ...tariffMappingEntity,
                index: index || 0
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, tariffMappingState.tariffMappingList]);

    return <><TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {memoTariffMappingTable}
    </TableWrapper>
    </>;
}

export default memo(TariffMappingTablePanel);

import { ActiveIndDroOpts } from "presentation/constant/DropDownOptions/Common/ActiveIndDroOpts";
import { bundleIndDropdownOption, cntrTspStatusDropdownOption, directDischargeIndValDropdownOption, rcTypeDropdownOption, reeferHandlingTypeDropdownOption, statusDropdownOptions } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { TariffMappingConstant, tariffMappingRequiredFieldList } from "presentation/constant/TariffMapping/TariffMappingConstant";
import { useTariffMappingVM } from "presentation/hook/TariffMapping/useTariffMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useTariffMappingTracked } from "presentation/store/TariffMapping/TariffMappingProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useMemo, useState } from "react";
import { FieldType, HPHBreadcrumb, HPHCheckbox, HPHGroupTitle, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, SidebarCaption, Sidebarheader, SidebarTitle, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TariffMappingEditPanel = () => {
    const TARIFF_MAPPING_CONSTANT = TariffMappingConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const [tariffMappingState] = useTariffMappingTracked();
    const tariffMappingVM = useTariffMappingVM();
    const { currentEditRow, isAdd, isEdit } = tariffMappingState;
    const messageBarVM = useMessageBarVM();


    const handleSave = useCallback(async () => {
        if (!currentEditRow.tariffType) {
            messageBarVM.showError("Tariff Type is mandatory.");
            return;
        }
        if (!currentEditRow.tariffCode) {
            messageBarVM.showError("Tariff Code is mandatory.");
            return;
        }
        if (!currentEditRow.chargeType) {
            messageBarVM.showError("Charge Type is mandatory.");
            return;
        }
        if (!currentEditRow.activeInd) {
            messageBarVM.showError("Active Ind is mandatory.");
            return;
        }
        if (currentEditRow.loaLimitFrom && (currentEditRow.loaLimitFrom < 0 || currentEditRow.loaLimitFrom > 9999999.99)) {
            messageBarVM.showError("Input Loa Limit From Value should be range from 0 to 9999999.99.");
            return;
        }
        if (currentEditRow.loaLimitTo && (currentEditRow.loaLimitTo < 0 || currentEditRow.loaLimitTo > 9999999.99)) {
            messageBarVM.showError("Input Loa Limit To Value should be range from 0 to 9999999.99.");
            return;
        }
        if (currentEditRow.overWeightFrom && (currentEditRow.overWeightFrom < 0 || currentEditRow.overWeightFrom > 9999999.99)) {
            messageBarVM.showError("Input Over Weight From Value should be range from 0 to 9999999.99.");
            return;
        }
        if (currentEditRow.overWeightTo && (currentEditRow.overWeightTo < 0 || currentEditRow.overWeightTo > 9999999.99)) {
            messageBarVM.showError("Input Over Weight To Value should be range from 0 to 9999999.99.");
            return;
        }
        setIsLoading(true);
        const data = await tariffMappingVM.onSave(currentEditRow);
        if (data && data.success) {
            messageBarVM.showSuccess("Save Successfully.");
            tariffMappingVM.searchAllTariffMappingList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        } else {
            setIsLoading(false)
            messageBarVM.showError(data.data);
            return;
        }
    }, [tariffMappingVM, currentEditRow, messageBarVM]);

    const handClose = useCallback(() => {
        tariffMappingVM.onCloseEidtPanel();
    }, [tariffMappingVM]);

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        tariffMappingVM.onHeaderFieldChange(fieldName, fieldValue);
    }, [tariffMappingVM]);

    const memoSubChgTypeOptions = useMemo(() => {
        const subChgTypeOptions = currentEditRow.chargeType ? tariffMappingState.dynamicOptions.subChargeTypeDropdownOptions[currentEditRow.chargeType] : [];
        return subChgTypeOptions;
    }, [currentEditRow.chargeType, tariffMappingState.dynamicOptions.subChargeTypeDropdownOptions])

    const memoTariffCodeOptions = useMemo(() => {
        return currentEditRow.tariffType
            ? tariffMappingState.dynamicOptions.tariffCodeDropdownOptions[currentEditRow.tariffType]
            : [];
    }, [currentEditRow.tariffType, tariffMappingState.dynamicOptions.tariffCodeDropdownOptions]);

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={false}
                readOnlyValue={currentEditRow?.chargeType || ''}
                fieldValue={currentEditRow?.chargeType}
                fieldLabel={TARIFF_MAPPING_CONSTANT.CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.chargeTypeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.CHARGE_TYPE, tariffMappingState.dynamicOptions.chargeTypeDropdownOptions, tariffMappingVM, currentEditRow?.chargeType, isAdd, isEdit])

    const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.subChargeType || ''}
                fieldValue={currentEditRow?.subChargeType}
                fieldLabel={TARIFF_MAPPING_CONSTANT.SUB_CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'subChargeType'}
                maxLength={60}
                options={memoSubChgTypeOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.SUB_CHARGE_TYPE, tariffMappingVM, currentEditRow?.subChargeType, isAdd, isEdit, memoSubChgTypeOptions])

    const memoOpsLine = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.opsLine || ''}
                fieldValue={currentEditRow?.opsLine}
                fieldLabel={TARIFF_MAPPING_CONSTANT.OPS_LINE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'opsLine'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.opsLineDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.OPS_LINE, tariffMappingState.dynamicOptions.opsLineDropdownOptions, tariffMappingVM, currentEditRow?.opsLine, isAdd, isEdit])

    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeOnCompanyCode || ''}
                fieldValue={currentEditRow?.chargeOnCompanyCode}
                fieldLabel={TARIFF_MAPPING_CONSTANT.CHARGEON_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompanyCode'}
                maxLength={60}
                sort={false}
                options={tariffMappingState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.CHARGEON_COMPANY, tariffMappingState.dynamicOptions.companyCodeDropdownOptions, tariffMappingVM, currentEditRow?.chargeOnCompanyCode, isAdd, isEdit])


    const memoSize = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cntrSize || ''}
                fieldValue={currentEditRow?.cntrSize}
                fieldLabel={TARIFF_MAPPING_CONSTANT.SIZE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrSize'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.cntrSizeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.SIZE, tariffMappingState.dynamicOptions.cntrSizeDropdownOptions, tariffMappingVM, currentEditRow?.cntrSize, isAdd, isEdit])

    const memoCntrType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cntrType || ''}
                fieldValue={currentEditRow?.cntrType}
                fieldLabel={TARIFF_MAPPING_CONSTANT.TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrType'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.cntrTypeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.TYPE, tariffMappingState.dynamicOptions.cntrTypeDropdownOptions, tariffMappingVM, currentEditRow?.cntrType, isAdd, isEdit])

    const memoCntrTypeGroup = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cntrTypeGroup || ''}
                fieldValue={currentEditRow?.cntrTypeGroup}
                fieldLabel={TARIFF_MAPPING_CONSTANT.CNTR_TYPE_GROUP}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrTypeGroup'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.cntrTypeGroupDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [isAdd, isEdit, currentEditRow?.cntrTypeGroup, TARIFF_MAPPING_CONSTANT.CNTR_TYPE_GROUP, tariffMappingState.dynamicOptions.cntrTypeGroupDropdownOptions, tariffMappingVM])

    const memoCntrStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cntrStatus || ''}
                fieldValue={currentEditRow?.cntrStatus}
                fieldLabel={TARIFF_MAPPING_CONSTANT.STATUS}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrStatus'}
                maxLength={60}
                options={statusDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.STATUS, tariffMappingVM, currentEditRow?.cntrStatus, isAdd, isEdit])

    const memoShipmentType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.shipmentType || ''}
                fieldValue={currentEditRow?.shipmentType}
                fieldLabel={TARIFF_MAPPING_CONSTANT.SHIPMENT_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'shipmentType'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.shipmentTypeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.SHIPMENT_TYPE, tariffMappingState.dynamicOptions.shipmentTypeDropdownOptions, tariffMappingVM, currentEditRow?.shipmentType, isAdd, isEdit])

    const memoServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.serviceCode || ''}
                fieldValue={currentEditRow?.serviceCode}
                fieldLabel={TARIFF_MAPPING_CONSTANT.SERVICE_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'serviceCode'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.serviceCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.SERVICE_CODE, tariffMappingState.dynamicOptions.serviceCodeDropdownOptions, tariffMappingVM, currentEditRow?.serviceCode, isAdd, isEdit])

    const memoModalityOfArrival = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.modalityOfArrival || ''}
                fieldValue={currentEditRow?.modalityOfArrival}
                fieldLabel={TARIFF_MAPPING_CONSTANT.MODALITY_OF_ARRIVAL}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfArrival'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.modalityDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.MODALITY_OF_ARRIVAL, tariffMappingState.dynamicOptions.modalityDropdownOptions, tariffMappingVM, currentEditRow?.modalityOfArrival, isAdd, isEdit])

    const memoModalityOfDeparture = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.modalityOfDeparture || ''}
                fieldValue={currentEditRow?.modalityOfDeparture}
                fieldLabel={TARIFF_MAPPING_CONSTANT.MODALITY_OF_DEPARTURE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfDeparture'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.modalityDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.MODALITY_OF_DEPARTURE, tariffMappingState.dynamicOptions.modalityDropdownOptions, tariffMappingVM, currentEditRow?.modalityOfDeparture, isAdd, isEdit])

    const memoMarshalFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.marshallingFrom || ''}
                fieldValue={currentEditRow?.marshallingFrom}
                fieldLabel={TARIFF_MAPPING_CONSTANT.MARSHALLING_FROM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'marshallingFrom'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.marshalCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.MARSHALLING_FROM, tariffMappingState.dynamicOptions.marshalCodeDropdownOptions, tariffMappingVM, currentEditRow?.marshallingFrom, isAdd, isEdit])

    const memoMarshalTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.marshallingTo || ''}
                fieldValue={currentEditRow?.marshallingTo}
                fieldLabel={TARIFF_MAPPING_CONSTANT.MARSHALLING_TO}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'marshallingTo'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.marshalCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.MARSHALLING_TO, tariffMappingState.dynamicOptions.marshalCodeDropdownOptions, tariffMappingVM, currentEditRow?.marshallingTo, isAdd, isEdit])

    const memoFromTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.fromTml || ''}
                fieldValue={currentEditRow?.fromTml}
                fieldLabel={TARIFF_MAPPING_CONSTANT.FROM_TML}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'fromTml'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.terminalCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.FROM_TML, tariffMappingState.dynamicOptions.terminalCodeDropdownOptions, tariffMappingVM, currentEditRow?.fromTml, isAdd, isEdit])

    const memoToTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.toTml || ''}
                fieldValue={currentEditRow?.toTml}
                fieldLabel={TARIFF_MAPPING_CONSTANT.TO_TML}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'toTml'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.terminalCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.TO_TML, tariffMappingState.dynamicOptions.terminalCodeDropdownOptions, tariffMappingVM, currentEditRow?.toTml, isAdd, isEdit])


    const memoWireSlingInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.wireSlingInd || ''}
                fieldValue={currentEditRow?.wireSlingInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.WIRE_SLING_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'wireSlingInd'}
                maxLength={60}
                options={ActiveIndDroOpts().getDroOptsModel()}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.WIRE_SLING_IND, tariffMappingVM, currentEditRow?.wireSlingInd, isAdd, isEdit])



    const memoMarshallingType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.marshallingType || ''}
                fieldValue={currentEditRow?.marshallingType}
                fieldLabel={TARIFF_MAPPING_CONSTANT.MARSHALLING_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'marshallingType'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.marshallingTypeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.MARSHALLING_TYPE, tariffMappingState.dynamicOptions.marshallingTypeDropdownOptions, tariffMappingVM, currentEditRow?.marshallingType, isAdd, isEdit])

    const memoChargeInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeInd || ''}
                fieldValue={currentEditRow?.chargeInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.CHARGE_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeInd'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.chargeIndDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.CHARGE_IND, tariffMappingState.dynamicOptions.chargeIndDropdownOptions, tariffMappingVM, currentEditRow?.chargeInd, isAdd, isEdit])

    const memoSpecialHandlingInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.specialHandlingInd || ''}
                fieldValue={currentEditRow?.specialHandlingInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.SP_HANDLING_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'specialHandlingInd'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.specHandingIndDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.SP_HANDLING_IND, tariffMappingState.dynamicOptions.specHandingIndDropdownOptions, tariffMappingVM, currentEditRow?.specialHandlingInd, isAdd, isEdit])

    const memoSpecialHandlingIndD1 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.legSpHandlingInd || ''}
                fieldValue={currentEditRow?.legSpHandlingInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.SP_HANDLING_IND_D}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'legSpHandlingInd'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.specHandingIndDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.SP_HANDLING_IND_D, tariffMappingState.dynamicOptions.specHandingIndDropdownOptions, tariffMappingVM, currentEditRow?.legSpHandlingInd, isAdd, isEdit])

    const memoBundleInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.bundleInd || ''}
                fieldValue={currentEditRow?.bundleInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.BUNDLE_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'bundleInd'}
                maxLength={60}
                options={bundleIndDropdownOption}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.BUNDLE_IND, tariffMappingVM, currentEditRow?.bundleInd, isAdd, isEdit])


    const memoTspStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cntrTspStatus || ''}
                fieldValue={currentEditRow?.cntrTspStatus}
                fieldLabel={TARIFF_MAPPING_CONSTANT.TRANSPORTATION_STATUS}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrTspStatus'}
                maxLength={60}
                options={cntrTspStatusDropdownOption}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.TRANSPORTATION_STATUS, tariffMappingVM, currentEditRow?.cntrTspStatus, isAdd, isEdit])



    const memoOperationType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.operationType || ''}
                fieldValue={currentEditRow?.operationType}
                fieldLabel={TARIFF_MAPPING_CONSTANT.OPERATION_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operationType'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.operationTypeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.OPERATION_TYPE, tariffMappingState.dynamicOptions.operationTypeDropdownOptions, tariffMappingVM, currentEditRow?.operationType, isAdd, isEdit])

    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={false}
                readOnlyValue={currentEditRow?.tariffType || ''}
                fieldValue={currentEditRow?.tariffType}
                fieldLabel={TARIFF_MAPPING_CONSTANT.TARIFF_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                options={tariffMappingState.dynamicOptions.tariffTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.TARIFF_TYPE, currentEditRow?.tariffType, isAdd, isEdit, tariffMappingState.dynamicOptions.tariffTypeDropdownOptions, tariffMappingVM])

    const memoTariffCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.tariffCode || ''}
                fieldValue={currentEditRow?.tariffCode}
                fieldLabel={TARIFF_MAPPING_CONSTANT.TARIFF_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                options={memoTariffCodeOptions}
                maxLength={60}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.TARIFF_CODE, currentEditRow?.tariffCode, isAdd, isEdit, memoTariffCodeOptions, tariffMappingVM])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={TARIFF_MAPPING_CONSTANT.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={currentEditRow?.activeInd === 'Y'}
                disabled={!(isAdd || isEdit)}
                onChange={(e) => tariffMappingVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        , [TARIFF_MAPPING_CONSTANT.ACTIVE_IND, tariffMappingVM, currentEditRow?.activeInd, isAdd, isEdit])

    const memoForwarderCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.forwarderCode || ''}
                fieldValue={currentEditRow?.forwarderCode}
                fieldLabel={TARIFF_MAPPING_CONSTANT.FORWARDER_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'forwarderCode'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.forwarderCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.FORWARDER_CODE, currentEditRow?.forwarderCode, isAdd, isEdit, tariffMappingState.dynamicOptions.forwarderCodeDropdownOptions, tariffMappingVM])
    const memoForwarderReference = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.forwarderRef || ''}
                fieldValue={currentEditRow?.forwarderRef}
                fieldLabel={TARIFF_MAPPING_CONSTANT.FORWARDER_REF}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'forwarderRef'}
                maxLength={60}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.FORWARDER_REF, currentEditRow?.forwarderRef, isAdd, isEdit, tariffMappingVM])

    const memoPhysicalHandlingCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.physicalHandlingCode || ''}
                fieldValue={currentEditRow?.physicalHandlingCode}
                fieldLabel={TARIFF_MAPPING_CONSTANT.CONTAINER_PHYSICAL_HANDLING_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'physicalHandlingCode'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.physicalHandlingCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.CONTAINER_PHYSICAL_HANDLING_CODE, currentEditRow?.physicalHandlingCode, isAdd, isEdit, tariffMappingState.dynamicOptions.physicalHandlingCodeDropdownOptions, tariffMappingVM])

    const memoConsortiumCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.consortiumCode || ''}
                fieldValue={currentEditRow?.consortiumCode}
                fieldLabel={TARIFF_MAPPING_CONSTANT.CONSORTIUM_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'consortiumCode'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.consortiumCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.CONSORTIUM_CODE, currentEditRow?.consortiumCode, isAdd, isEdit, tariffMappingState.dynamicOptions.consortiumCodeDropdownOptions, tariffMappingVM])

    const memoLoaLimitFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NumberInputComponent
                label={TARIFF_MAPPING_CONSTANT.LOA_LIMIT_FROM}
                maxLength={60}
                fieldName={"loaLimitFrom"}
                value={currentEditRow?.loaLimitFrom ?? ''}
                errorMessage={''}
                disabled={!(isAdd || isEdit)}
                decimalPlaces={2}
                optional={true}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [TARIFF_MAPPING_CONSTANT.LOA_LIMIT_FROM, currentEditRow?.loaLimitFrom, isAdd, isEdit, onNumberFieldChange])

    const memoLoaLimitTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NumberInputComponent
                label={TARIFF_MAPPING_CONSTANT.LOA_LIMIT_TO}
                maxLength={60}
                fieldName={"loaLimitTo"}
                value={currentEditRow?.loaLimitTo ?? ''}
                errorMessage={''}
                disabled={!(isAdd || isEdit)}
                decimalPlaces={2}
                optional={true}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [TARIFF_MAPPING_CONSTANT.LOA_LIMIT_TO, currentEditRow?.loaLimitTo, isAdd, isEdit, onNumberFieldChange])

    const memoDirectInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.directInd || ''}
                fieldValue={currentEditRow?.directInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.DIRECT_DISCHARGE_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'directInd'}
                maxLength={60}
                options={directDischargeIndValDropdownOption}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.DIRECT_DISCHARGE_IND, currentEditRow?.directInd, isAdd, isEdit, tariffMappingVM])

    const memoReeferHandlingType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.reeferHandlingType || ''}
                fieldValue={currentEditRow?.reeferHandlingType}
                fieldLabel={TARIFF_MAPPING_CONSTANT.REEFER_HANDLING_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'reeferHandlingType'}
                maxLength={60}
                options={reeferHandlingTypeDropdownOption}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.REEFER_HANDLING_TYPE, currentEditRow?.reeferHandlingType, isAdd, isEdit, tariffMappingVM])

    const memoServiceOrderCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.serviceOrderCode || ''}
                fieldValue={currentEditRow?.serviceOrderCode}
                fieldLabel={TARIFF_MAPPING_CONSTANT.SERVICE_ORDER_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'serviceOrderCode'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.serviceOrderCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.SERVICE_ORDER_CODE, currentEditRow?.serviceOrderCode, isAdd, isEdit, tariffMappingState.dynamicOptions.serviceOrderCodeDropdownOptions, tariffMappingVM])

    const memoOverWeightFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NumberInputComponent
                label={TARIFF_MAPPING_CONSTANT.OVER_WEIGHT_FROM}
                maxLength={60}
                fieldName={"overWeightFrom"}
                value={currentEditRow?.overWeightFrom ?? ''}
                errorMessage={''}
                disabled={!(isAdd || isEdit)}
                decimalPlaces={2}
                optional={true}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [TARIFF_MAPPING_CONSTANT.OVER_WEIGHT_FROM, currentEditRow?.overWeightFrom, isAdd, isEdit, onNumberFieldChange])

    const memoOverWeightTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NumberInputComponent
                label={TARIFF_MAPPING_CONSTANT.OVER_WEIGHT_TO}
                maxLength={60}
                fieldName={"overWeightTo"}
                value={currentEditRow?.overWeightTo ?? ''}
                errorMessage={''}
                disabled={!(isAdd || isEdit)}
                decimalPlaces={2}
                optional={true}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [TARIFF_MAPPING_CONSTANT.OVER_WEIGHT_TO, currentEditRow?.overWeightTo, isAdd, isEdit, onNumberFieldChange])

    const memoCargoType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cargoType || ''}
                fieldValue={currentEditRow?.cargoType}
                fieldLabel={TARIFF_MAPPING_CONSTANT.CARGO_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cargoType'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.cargoTypeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.CARGO_TYPE, currentEditRow?.cargoType, isAdd, isEdit, tariffMappingState.dynamicOptions.cargoTypeDropdownOptions, tariffMappingVM])

    const memoCargoCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cargoCode || ''}
                fieldValue={currentEditRow?.cargoCode}
                fieldLabel={TARIFF_MAPPING_CONSTANT.CARGO_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cargoCode'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.cargoCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.CARGO_CODE, currentEditRow?.cargoCode, isAdd, isEdit, tariffMappingState.dynamicOptions.cargoCodeDropdownOptions, tariffMappingVM])


    const memoSpecialArea = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.specialArea || ''}
                fieldValue={currentEditRow?.specialArea}
                fieldLabel={TARIFF_MAPPING_CONSTANT.SPECIAL_AREA}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'specialArea'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.specialAreaDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.SPECIAL_AREA, currentEditRow?.specialArea, isAdd, isEdit, tariffMappingState.dynamicOptions.specialAreaDropdownOptions, tariffMappingVM])

    const memoPalletizedInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.palletizedInd || ''}
                fieldValue={currentEditRow?.palletizedInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.PALLETIZED_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'palletizedInd'}
                maxLength={60}
                options={ActiveIndDroOpts().getDroOptsModel()}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.PALLETIZED_IND, currentEditRow?.palletizedInd, isAdd, isEdit, tariffMappingVM])

    const memoRoundTrip = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.roundTripFlag || ''}
                fieldValue={currentEditRow?.roundTripFlag}
                fieldLabel={TARIFF_MAPPING_CONSTANT.ROUND_TRIP}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'roundTripFlag'}
                maxLength={60}
                options={ActiveIndDroOpts().getDroOptsModel()}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.ROUND_TRIP, currentEditRow?.roundTripFlag, isAdd, isEdit, tariffMappingVM])

    const memoSpecialInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.specialInd || ''}
                fieldValue={currentEditRow?.specialInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.SPECIAL_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'specialInd'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.specialIndDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.SPECIAL_IND, currentEditRow?.specialInd, isAdd, isEdit, tariffMappingState.dynamicOptions.specialIndDropdownOptions, tariffMappingVM])

    const memoTsLegChargeInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.tsLegChargeInd || ''}
                fieldValue={currentEditRow?.tsLegChargeInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.TRANSHIPMENT_LEG_CHARGE_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tsLegChargeInd'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.transhipmentLegChargeIndDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.TRANSHIPMENT_LEG_CHARGE_IND, currentEditRow?.tsLegChargeInd, isAdd, isEdit, tariffMappingState.dynamicOptions.transhipmentLegChargeIndDropdownOptions, tariffMappingVM])

    const memoOverWeightInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.overWeightInd || ''}
                fieldValue={currentEditRow?.overWeightInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.OVER_WIDTH_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'overWeightInd'}
                maxLength={60}
                options={ActiveIndDroOpts().getDroOptsModel()}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.OVER_WIDTH_IND, currentEditRow?.overWeightInd, isAdd, isEdit, tariffMappingVM])



    const memoOverDimensionInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.overDimensionInd || ''}
                fieldValue={currentEditRow?.overDimensionInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.OVER_DIMENSION_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'overDimensionInd'}
                maxLength={60}
                options={ActiveIndDroOpts().getDroOptsModel()}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.OVER_DIMENSION_IND, currentEditRow?.overDimensionInd, isAdd, isEdit, tariffMappingVM])


    const memoEmptyIndicator = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.emptyIndicator || ''}
                fieldValue={currentEditRow?.emptyIndicator}
                fieldLabel={TARIFF_MAPPING_CONSTANT.EMPTY_INDICATOR}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'emptyIndicator'}
                maxLength={60}
                options={ActiveIndDroOpts().getDroOptsModel()}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.EMPTY_INDICATOR, currentEditRow?.emptyIndicator, isAdd, isEdit, tariffMappingVM])


    const memoNonCellularVslInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.nonCellularVslInd || ''}
                fieldValue={currentEditRow?.nonCellularVslInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.NONCELLULAR_VSL_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'nonCellularVslInd'}
                maxLength={60}
                options={ActiveIndDroOpts().getDroOptsModel()}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.NONCELLULAR_VSL_IND, currentEditRow?.nonCellularVslInd, isAdd, isEdit, tariffMappingVM])

    const memoEquipmentCodeInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.equipmentCodeInd || ''}
                fieldValue={currentEditRow?.equipmentCodeInd}
                fieldLabel={TARIFF_MAPPING_CONSTANT.EQUIPMENT_CODE_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'equipmentCodeInd'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.equipmentCodeIndDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.EQUIPMENT_CODE_IND, currentEditRow?.equipmentCodeInd, isAdd, isEdit, tariffMappingState.dynamicOptions.equipmentCodeIndDropdownOptions, tariffMappingVM])


    const memoShiftCodeOps = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.shiftCodeOps || ''}
                fieldValue={currentEditRow?.shiftCodeOps}
                fieldLabel={TARIFF_MAPPING_CONSTANT.SHIFT_CODEOPS}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'shiftCodeOps'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.shiftCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.SHIFT_CODEOPS, currentEditRow?.shiftCodeOps, isAdd, isEdit, tariffMappingState.dynamicOptions.shiftCodeDropdownOptions, tariffMappingVM])


    const memoRcType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.rcType || ''}
                fieldValue={currentEditRow?.rcType}
                fieldLabel={TARIFF_MAPPING_CONSTANT.REHANDLING_CANCEL_SHIP_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'rcType'}
                maxLength={60}
                options={rcTypeDropdownOption}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.REHANDLING_CANCEL_SHIP_TYPE, currentEditRow?.rcType, isAdd, isEdit, tariffMappingVM])


    const memoPartnerCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "300px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.partnerCode || ''}
                fieldValue={currentEditRow?.partnerCode}
                fieldLabel={TARIFF_MAPPING_CONSTANT.PARTNER_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'partnerCode'}
                maxLength={60}
                options={tariffMappingState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={tariffMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [TARIFF_MAPPING_CONSTANT.PARTNER_CODE, currentEditRow?.partnerCode, isAdd, isEdit, tariffMappingState.dynamicOptions.companyCodeDropdownOptions, tariffMappingVM])

    const handleEdit = useCallback(() => {
        tariffMappingVM.onEditClick();
    }, [tariffMappingVM]);

    const handleReset = useCallback(() => {
        tariffMappingVM.onResetClick(isAdd);
    }, [tariffMappingVM, isAdd]);

    return <>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
            <Sidebarheader style={{ width: '100%' }}>
                {isLoading && <Loader Indicator="Spinner" size="Medium" />}
                <Breadcrumb>
                    <HPHBreadcrumb breadcrumbData={[{ title: TariffMappingConstant.Title.TITLE },
                    { title: TariffMappingConstant.Title.TITLE }]} onCurrentClick={() => { }}>
                    </HPHBreadcrumb>
                </Breadcrumb>
                <StyledAction>
                    <>
                        {!(isAdd || isEdit) && <IconButton fileName='Icon-pen' size='medium' disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} onClick={handleEdit} />}
                        {(isAdd || isEdit) && <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />}
                        <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                        {(isAdd || isEdit) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
                    </>
                </StyledAction>
            </Sidebarheader>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'visible', zIndex: '99' }}>
                <div className="flex-row-auto" style={{ overflow: 'visible', zIndex: '100' }}>
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>
                                    {isAdd && "New Tariff Setting"}
                                    {!isAdd && (currentEditRow.priority + "/" + currentEditRow.tariffType + "/" + currentEditRow.tariffCode)}
                                </SidebarTitle>
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        <br />
                        <HPHGroupTitle titleText={TariffMappingConstant.Title.MAPPING_FROM} />
                        <CriteriaItemContainer>
                            {memoChargeType}
                            {memoSubChargeType}
                            {memoChargeOnCompany}
                            {memoOpsLine}
                        </CriteriaItemContainer>
                        <label>CONTAINER</label>
                        <CriteriaItemContainer>
                            {memoSize}
                            {memoCntrStatus}
                            {memoCntrType}
                            {memoCntrTypeGroup}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoShipmentType}
                            {memoPhysicalHandlingCode}
                            {memoForwarderCode}
                            {memoForwarderReference}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoConsortiumCode}
                            {memoServiceCode}
                            {memoLoaLimitFrom}
                            {memoLoaLimitTo}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoModalityOfArrival}
                            {memoModalityOfDeparture}
                            {memoReeferHandlingType}
                            {memoMarshallingType}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoMarshalFrom}
                            {memoMarshalTo}
                            {memoFromTml}
                            {memoToTml}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoOverWeightFrom}
                            {memoOverWeightTo}
                            {memoDirectInd}
                            {memoWireSlingInd}
                        </CriteriaItemContainer>

                        <label>OTHERS</label>
                        <CriteriaItemContainer>
                            {memoCargoType}
                            {memoCargoCode}
                            {memoServiceOrderCode}
                            {memoPalletizedInd}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoSpecialArea}
                            {memoSpecialInd}
                            {memoChargeInd}
                            {memoSpecialHandlingIndD1}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoSpecialHandlingInd}
                            {memoTsLegChargeInd}
                            {memoOverWeightInd}
                            {memoOverDimensionInd}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoEquipmentCodeInd}
                            {memoShiftCodeOps}
                            {memoBundleInd}
                            {memoNonCellularVslInd}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoRcType}
                            {memoPartnerCode}
                            {memoTspStatus}
                            {memoEmptyIndicator}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoOperationType}
                            {memoRoundTrip}
                        </CriteriaItemContainer>

                        <HPHGroupTitle titleText={TariffMappingConstant.Title.MAPPING_TO} />
                        <CriteriaItemContainer>
                            {memoTariffType}
                            {memoTariffCode}
                            {memoActiveInd}
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>
        </StyledSidebar>
    </>
}

export default memo(TariffMappingEditPanel);
